import React, { useState } from 'react'
import { Button, IconButton, SwipeableDrawer } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'

import NavLinks from '../navLinks/navLinks'

const Drawer = () => {
    const [openDrawer, setDrawerOpen] = useState(false)
    // const iOS = typeof window === 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

    const toggleDrawer = open => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setDrawerOpen(open)
    }

    const mobileNavContents = (
        <div
            role="presentation"
            className="drawerContents"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <NavLinks />
        </div>
    )

    return (
        <>
            <Button
                size="large"
                disableElevation
                aria-label="menu"
                variant="contained"
                startIcon={<MenuIcon />}
                classes={{
                    root: 'navMenuButton',
                    startIcon: 'navMenuButtonIcon',
                }}
                onClick={toggleDrawer(true)}
            >
                <span className="navMenuButtonText">Menu</span>
            </Button>
            <SwipeableDrawer
                anchor="right"
                open={openDrawer}
                classes={{ paper: 'drawer' }}
                disableDiscovery
                onOpen={toggleDrawer(true)}
                onClose={toggleDrawer(false)}
                // disableBackdropTransition={!iOS}
            >
                {mobileNavContents}
                <IconButton
                    aria-label="close"
                    className="closeButton"
                    onClick={toggleDrawer(false)}
                    size="large"
                >
                    <CloseIcon fontSize="large" />
                </IconButton>
            </SwipeableDrawer>
        </>
    )
}

export default Drawer
