export const Homepage = {
    id: 'homepage',
    path: '/',
    scrollPath: '/',
}

export const Purpose = {
    id: 'purpose',
    path: '/purpose/',
    scrollPath: '/#purpose',
}

export const Expertise = {
    id: 'expertise',
    path: '/expertise/',
    scrollPath: '/#expertise',
}

export const Team = {
    id: 'team',
    path: '/team/',
    scrollPath: '/#team',
}

export const Contact = {
    id: 'contact',
    path: '/contact/',
    scrollPath: '/#contact',
}

const NavLinks = [Homepage, Purpose, Expertise, Team, Contact]

export default NavLinks
