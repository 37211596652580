import React from 'react'
import { AppBar, Toolbar, IconButton, Button } from '@mui/material'

import Drawer from '../drawer/drawer'
import Link from '../link'
import { Homepage } from '../../utils/navLinks'

import FullLogo from '../../assets/svg/fullLogo.svg'

const Header = () => (
    <AppBar position="fixed">
        <Toolbar component="nav" disableGutters>
            <div className="navLHS">
                <IconButton
                    to={Homepage.path}
                    component={Link}
                    aria-label="home"
                    className="navLogo"
                    size="large"
                >
                    <FullLogo />
                </IconButton>
            </div>
            <div className="navRHS">
                <Button
                    size="large"
                    to="/#contact"
                    component={Link}
                    variant="contained"
                    disableElevation
                    className="contactButton"
                >
                    Contact us
                </Button>
                <Drawer />
            </div>
        </Toolbar>
    </AppBar>
)

export default Header
