import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import Link from '../link'
import NavLinks, { Homepage } from '../../utils/navLinks'

const navLinks = ({ omitHome }) => {
    const { t } = useTranslation()

    return (
        <div className="navLinks">
            {(omitHome ? NavLinks.filter(navLink => navLink !== Homepage) : NavLinks).map(
                navLink => (
                    <Button
                        to={navLink.scrollPath}
                        component={Link}
                        key={navLink.id}
                        className="navLink"
                    >
                        {t(`${navLink.id}:title`)}
                    </Button>
                ),
            )}
        </div>
    )
}

export default navLinks
